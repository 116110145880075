import './App.css';
import Navbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import QuickLink from './components/BottomBar';
import {EMAIL, MOBILE} from "../src/constants";

function App({children}) {
  return (
    <div className="App" style={{height: "100vh"}}>
      <div style={{backgroundColor: "#bdb746", textAlign: "center"}}>
        <span style={{marginRight: "20px"}}> <FaPhoneAlt /> {MOBILE}</span>
        <span> <MdEmail /> {EMAIL}</span>
      </div>
      <Navbar />
      {children}
      <QuickLink />
    </div>
  );
}

export default App;
