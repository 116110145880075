import "./BottomBar.css";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {EMAIL, MOBILE} from "../constants";

function BottomBar(){

    return (
        <div className="bottom-bar">
            <h3 >CONTACT</h3>
            <h4> <FaPhoneAlt/> {MOBILE}</h4>
            <h4><MdEmail /> {EMAIL}</h4>
        </div>
        
    )
}

export default BottomBar;
