import {
  createBrowserRouter,
} from "react-router-dom";
import Home from "./Home";
import App from "../App";
import Contact from "./Contact";
import About from "./About";
import Career from "./Career";
import Services from "./Services";
import Cloud from "./Cloud"
import Analytics from "./Analytics";
import Api from "./Api"
const router = createBrowserRouter([
  {
    path: "/home",
    element: <App  ><Home/></App>
  },
  {
    path: "/",
    element: <App  ><Home/></App>
  },
  {
    path: "/contact-us",
    element: <App ><Contact/></App>
  },
  {
    path:"/about",
    element: <App><About /></App>
  },
  {
    path:"/careers",
    element: <App><Career /></App>
  },
  {
    path:"/services",
    element: <App><Services/></App>
  },
  {
    path: "/analytics",
    element: <App><Analytics/></App>
  },
  {
    path: "/cloud",
    element: <App><Cloud/></App>
  },
  {
    path: "/api",
    element: <App><Api/></App>
  }
  
  
]);

export default router;