
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaLocationDot } from "react-icons/fa6";


function About(){
    return (

        <>


        

        <Container >

            <Row style={{marginTop:"30px"}}>

                <Col>
                    <h1>Overview</h1>
                    <p style={{fontSize: "20px"}}>RD Solutions has a track record to<br/>
                        deliver services for clientslocated across the US</p>

                </Col>

                <Col>

                    <p style={{fontSize: "20px"}}>
                            RD Solutions is a Technology Company with a Focus on Digital Trends transforming our client’s IT landscape.
                            <br/> We deliver a full range of services including IT consulting, application development, testing & enterprise solutions.
                            <br/> Our committed teams possess a unique blend of technical and functional knowledge. 
                    </p> 

                
                </Col>
            </Row>


            <Row style={{marginTop: "40px"}}>
                <h1 style={{textAlign: "center", textUnderlineOffset: "15px",textDecoration:"underline"}}>Core Values</h1>
            </Row>



            <Row style={{marginTop: "40px"}}>
                <Col style={{textAlign:"center"}}>
                    <h2 >Integrity</h2>
                    <p>Integrity is the cornerstone of our culture.<br/>
                    We conduct business with consistently high moral and ethical standards.</p>
                </Col>

                <Col style={{textAlign:"center"}}>
                    <h2 >Client Success</h2>
                    <p>
                        Client Success is at the core of our value system.<br/>
                        We see ourselves as an extended arm of our client’s team.<br/>
                        We exhibit the highest level of partnership and flexibility ensuring<br/>
                        we tailor to our client’s needs<br/>
                    </p>
                </Col>
            </Row>


            <Row style={{marginTop: "40px", textAlign:"center"}}>
                    <h2>Relationships</h2>
                    <p>At RD Solutions, we care and nurture our relationships</p>
            </Row>




            <Row style={{marginTop: "50px"}}>

                <Col>
                    <p style={{fontSize: "15px"}}><b><u>Clients</u></b> - we strive to keep our clients happy by continuously innovating to ensure they receive cost-efficient and high-quality solutions.</p>
                </Col>

                <Col>
                    <p style={{fontSize: "15px"}}><b><u>Employees</u></b> - we strive to create a fun & highly motivated work environment with a focus on work-life balance, skill development, and general well-being</p>
                </Col>

                <Col>
                    <p style={{fontSize: "15px"}}><b><u>Partners</u></b> - we strive to consistently honor mutual commitments while working in concert to create value for our clients</p>
                </Col>
            </Row>



            <Row style={{marginTop: "50px"}}>

                <Col style={{textAlign:"center"}}>
                    <FaLocationDot/>
                    <h3> USA</h3>
                </Col>

                <Col style={{textAlign:"center"}}>
                    <FaLocationDot/>
                    <h3> INDIA</h3>
                </Col>
            </Row>

            


        </Container>

        </>

    )

}

export default About;