import { Image } from "react-bootstrap";
import analytics from "../images/analytics.jpg";
import "./Analytics.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardGroup from "react-bootstrap/CardGroup";

function Analytics() {
  return (
    <>
      {/* <img src={analytics} style={{width: "100vw", height: "40vh"}} alt="analytics"/> */}

      <div className="container-analytics">
        <img id="banner-analytics" src={analytics} alt="cloud" />
      </div>

      <Container style={{ marginBottom: "30px" }}>
        <Row style={{ marginTop: "20px" }}>
          <h1>Insights & Analytics</h1>
        </Row>
        <br />

        <Row>
          <Col>
            <p style={{ fontSize: "20px" }}>
              No matter where you are in the journey of
              <br />
              Data & Analytics, we can help you with the customized services you
              need
            </p>
          </Col>

          <Col>
            <p style={{ fontSize: "20px" }}>
              Enterprise Data integrity is the foundation on which enterprises
              pivot their digital transformation strategy.
            </p>

            <p style={{ fontSize: "20px" }}>
              Enterprise digital platforms & solutions are only effective if
              they deliver strong capabilities to provide deep insights &
              value-added services
              <br />
              from the ever-changing data landscape, both historical & real-time
            </p>
          </Col>
        </Row>

        <Row
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "30px",
          }}
        >
          <h1>RD Solutions Data Services</h1>
        </Row>

        <Row
          style={{
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "30px",
            fontSize: "20px",
          }}
        >
          <p>
            To make the best out of them, enterprises globally are focusing to
            capitalize
            <br />
            on this data to get real-time insights into their customer behavior
          </p>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Data</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Data Modernization</li>
                    <li>Data Quality</li>
                    <li>Data Governance</li>
                    <li>MDM</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Presentation</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Data Visualization</li>
                    <li>Business Intelligence</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Intelligent Analytics</Card.Title>
                <Card.Text>
                  <ul>
                    <li>Big Data Analytics</li>
                    <li>Data Science</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Analytics;
