
import "./Home.css"
import Image from 'react-bootstrap/Image';
import rdSol from "../images/rd_sol.jpg"

import computer from "../images/computer.jpg"
import devops from "../images/devops.jpg"
import pc from "../images/pc.jpg"
function Home(){



    
    return (

        <>



        <div className="container-home">
            <Image fluid className="banner-home" src={computer} alt="cloud"/>

            <div className="topleft-home" >Data Driven Digital Enterprise</div>
        </div>

        <div className="container-home">
            <Image fluid className="banner-home" src={devops} alt="cloud"/>

            <div className="topleft-home" style={{color:"white"}}>Devops solutions</div>
        </div>

        <div className="container-home">
            <Image fluid className="banner-home" src={pc} alt="cloud"/>

            <div className="topleft-home" style={{color:"white"}}>Middleware services</div>
        </div>

            
            {/* <div className="first-image">
                <h1 style={{ position: "relative",top: "30%",left: "5%", fontSize: "80px"}}>Data Driven Digital Enterprise</h1>
            </div>
            <div className="second-image">
                <h1 style={{ position: "relative",top: "30%",left:"10%", fontSize: "70px", color: "white"}}>Devops solutions</h1>
            </div>
            <div className="third-image">
                <h1 style={{ position: "relative",top: "30%",left:"10%", fontSize: "70px", color: "white"}}>Middleware services</h1>
            </div> */}

            <div style={{textAlign: "center", marginTop: "30px", marginBottom: "30px"}}>

                <h1> Focus on the next</h1>

                <h1 style={{display:"inline-block"}}>Why</h1> <Image src={rdSol} fluid />

                <h3>RD Solutions is driven to provide phenomenal outcomes to all our stakeholders </h3>
                <h3>which includes – customers, partners, employees, community and aspirants who share similar commitment.</h3>

            </div>

                <div className="reliable-partner">
                    <h3 style={{color: "white", padding: "10px", margin:"10%", backgroundColor:"black",opacity:"0.7"}}>
                        Reliable Partner <br/>
                        A Long-Term Player Development model (LTPD) is a framework to maximize a player's potential 
                        and long term involvement in sport over the course of their life.
                    </h3>
                </div>

                <div className="global-teams">
                    <h3 style={{color: "white", padding: "10px", margin:"10%", backgroundColor:"black",opacity:"0.7"}}>
                        Global Teams <br/>
                        Global teams often consist of employees who work remotely across different countries and time zones.
                </h3>
                </div>

                <div className="strong-leadership">
                    <h3 style={{color: "white", padding: "10px", margin:"10%", backgroundColor:"black",opacity:"0.7"}}>
                        Strong leadership Teams <br/>
                        Promote high potentials who possess the leadership skills needed for the role and leadership team's goals.
                    </h3>
                </div>

                <div className="certifications">
                    <h3 style={{color: "white", padding: "10px", margin:"10%", backgroundColor:"black",opacity:"0.7"}}>
                        Certifications <br/>
                        Promote high potentials who possess the leadership skills needed for the role and leadership team's goals.
                    </h3>
                </div>

            

        </>


        //  <div className="outer-home">
        //     <div className="about-us">
        //         <h3>About RD Solutions</h3>

        //         <p>RD Solutions as it’s name suggests is driven to provide phenomenal outcomes to all our stakeholders which includes – customers, partners, employees, community and aspirants who share similar commitment.
        //             </p>

        //         <p>We offer valuable partnership to our clients helping them do what we are good at and let them focus on their “core” business functions. We are not a one-size-fits-all vendor, we offer what clients are looking for after careful analysis of their expectations</p>
        //     </div>

            // <div className="services">

            //     <CardGroup>
            //         <Card>
            //             <Card.Img variant="top" src={MiddlewareImage} style={{ height: "200px"}}/>
            //             <Card.Body style={{backgroundColor: "#82b2e8" }}>
            //             <Card.Title>Middleware Solutions</Card.Title>
            //             <Card.Text >
            //                 We Offer Services on Microsoft BizTalk Server is an inter-organizational middleware system that automates business processes through the use of adapters which are tailored to communicate with different software systems used in an enterprise
            //             </Card.Text>
            //             </Card.Body>
                        
            //         </Card>

            //         <Card>
            //             <Card.Img variant="top" src={DevopsImage} style={{ height: "200px"}} />
            //             <Card.Body>
            //             <Card.Title>Devops Solutions</Card.Title>
            //             <Card.Text>
            //                 Use end-to-end solutions on Azure to implement DevOps practices throughout application planning, development, delivery, and operations. Apply the right combination of DevOps technologies, culture, and processes to enable continual software delivery and better value for customers.
            //             </Card.Text>
            //             </Card.Body>
                        
            //         </Card>

                    
            //         <Card>
            //             <Card.Img variant="top" src={DataMgmt} style={{ height: "200px"}} />
            //             <Card.Body style={{backgroundColor:"#337bcc"}}>
            //             <Card.Title>Data Management Solutions</Card.Title>
            //             <Card.Text>
            //                Data Management SolutionsData Solutions practice focuses on solution delivery of Data Management discipline which includes – Data Integration, Data Quality, Data Governance, Data Privacy, Data Standards, Metadata Management, Data Sharing, Data Analytics.
            //             </Card.Text>
            //             </Card.Body>
            //         </Card>
                    
            //         </CardGroup>

            // </div>


        // </div>

        
    )
}

export default Home;