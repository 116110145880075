import careerImg from "../images/careers.jpg"
import Image from 'react-bootstrap/Image';
import "./Career.css"
import { Container, Row, Col } from "react-bootstrap";
import Card from 'react-bootstrap/Card';

function Career(){
    return (

        <>
            <div id="career-imgs" >

            </div>

            <Container>

                <Row style={{marginTop:"30px", marginBottom: "50px"}}>
                    <Col>

                        <h1>Build Technology with RD Solutions</h1>

                        <p style={{fontSize: "20px", fontStyle:"italic"}}>
                            If you are technology savvy, self-motivated<br/>
                            and have the passion for making a difference,<br/>
                            you can be part of our team!<br></br>
                        </p>
                    </Col>

                    <Col>

                    <p style={{fontSize: "20px"}} >

                        Collaborate with clients and associates across the globe.<br/>
                        Build solutions, share knowledge, tackle complex problems and work on a diverse team<br/>
                        to engineer innovative and real-world solutions.
                    </p>

                    <p style={{fontSize: "20px"}}>

                        Take on exciting projects that continuously sharpen your technical skills,<br/>
                        your talent for solving problems and your entrepreneurial spirit.<br/> 
                        Accomplish this with experienced colleagues and supportive leaders who value your opinions.
                    </p>

                    <p style={{fontSize: "20px"}}>

                        Explore the endless possibilities every day, as you work with exceptional people, <br/>
                        latest technologies, and leading organizations across the globe
                    </p>
                    </Col>
                </Row>

                <Row style={{marginTop:"30px", marginBottom:"30px"}}>
                    <h1>Careers</h1>
                </Row>

                <Row>
                    <Col>
                        <Card style={{ width: '100%' }}>
                        <Card.Body>
                            <Card.Title><b>Biztalk Developer</b></Card.Title>
                            <Card.Text>
                            <p><b>Job Type:</b> Contract</p>
                            <p><b>Job Location</b>: USA</p>

                            <b>Skills:</b>

                            <ul>
                                <li>Senior Biztalk Engineer's work in conjunction with developers of all skill levels, business analysts, and business owners</li>
                                <li>Contributes to technical design including high-level conceptual diagram, Biztalk development and mapping, service contracts technologies (open to other languages) in an Agile environment</li>
                                <li>
                                    Work on the integrations team using Biztalk and Microsoft technologies
                                    Producing high quality code and addressing technical debt
                                </li>
                                <li>
                                    Carry out root cause analysis on defects to ensure fixes are happening in the right way
                                    Provide mentorship to teammates
                                </li>
                                <li>
                                    Work hand in hand with product owner to break down user stories
                                </li>
                                <li>Work to continuously advance your skill set</li>
                                <li>Managing software risk, balancing priorities, going above and beyond the call of duty
                                    Position Requirements</li>
                            </ul>

                            <p>4 to 7 years developing commercial-grade business applications leveraging the following:</p>

                            <ul>

                                <li>Writing code in an OO programming language</li>
                                <li>Build or maintain fault tolerant systems</li>
                                <li>Data structure and algorithms</li>
                            </ul>
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>



                    <Col>
                        <Card style={{ width: '100%%' }}>
                        <Card.Body>
                            <Card.Title><b>Devops Engineer</b></Card.Title>
                            <Card.Text>
                            <p><b>Job Type:</b> Contract</p>
                            <p><b>Job Location</b>: USA</p>

                            <b>Skills:</b>

                            <ul>
                                <li>This position will primarily focus on implementing Data Engineering and DevOps principles to operationalize data pipelines managed by the ComPsy group, as well as designing and implementing better ones</li>
                                <li>This position will require the candidate build data pipelines for ingestion, management, and analysis and build data engineering, data science, and machine learning pipelines on single-node machines or clusters</li>

                                <li>This position would primarily focus on implementing DevOps principles through agile/lean/continuous delivery methods to operationalize data and analytics platform and products</li>

                                <li>Establish best practices for managing infrastructure as code</li>

                                <li>Build and manage on premise and cloud data/analytics platforms and products</li>

                                <li>Identify opportunities for cost optimization and improve speed of delivery</li>
                            </ul>

                            
                            </Card.Text>
                        </Card.Body>
                        </Card>
                    </Col>


                </Row>


                
            </Container>

                <br/><br/>


        

       
            
        
        </>

        // <img src={careerImg} />
    )
}

export default Career;