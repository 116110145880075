import cloud from "../images/cloud.jpg"
import "./Cloud.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import azureImg from "../images/microsoftAzureCloud.png";
import ibmImg from "../images/IBMCloud-150x94.png";
import awsImg from "../images/amazonWebServices-150x94.png";
import gcpImg from "../images/googleCloudPlatform-150x94.png";
import Image from 'react-bootstrap/Image';


function Cloud(){
    return (
        <>


        <div className="container-cloud">
            <img  id="banner-cloud" src={cloud} alt="cloud"/>

            <div className="topleft-cloud">Cloud & Modernization</div>
        </div>

        

        <Container style={{marginBottom:"30px"}}>
                

                <Row style={{marginTop:"20px"}}>
                    <h1>Cloud & Modernization</h1>
                </Row>
                <br/>

                <Row>

                    <Col>

                    <p style={{fontSize:"20px"}}> RD Solutions offers Cloud Consulting, Application Development, Testing, & Migration services for enterprises
globally.</p>
                    </Col>

                    <Col>

                    <p style={{fontSize:"20px"}}>
                        Embrace the cloud faster with our global team of consultants who can help you build the right cloud for your business while evolving & modernizing the team’s culture & skills. Our cloud services help in leveraging technology, optimizing resources, & streamlining business processes to reduce costs & gain a competitive advantage for your customers
                    </p>
                    </Col>
                </Row>

                <Row style={{textAlign:"center", marginTop:"30px", marginBottom:"30px"}}>
                    <h1>Services Offered</h1>
                </Row>

                <Row style={{textAlign:"center", marginTop:"30px", marginBottom:"30px", fontSize:"20px"}}>
                    <p>Our cloud services help in leveraging technology, optimizing resources,
& streamlining business processes   </p>
                </Row>

                <Row>

                    <Col>
                        <Card>
                            <Card.Body>
                            <Card.Title>Cloud Migration</Card.Title>
                            <Card.Text>
                                <p>
                                    We assess your current applications & find the right mix of clouds for your workloads, identify gaps in technology & skills, & make a migration plan.
                                </p>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <Card.Body>
                            <Card.Title>Cloud Engineering</Card.Title>
                            <Card.Text>
                                <p>
                                    Design the cloud services to innovate faster with technology & methodologies that provide developers with fast, easy access to the resources needed
                                </p>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card>
                            <Card.Body>
                            <Card.Title>Cloud Operations & Management</Card.Title>
                            <Card.Text>
                                <p>
                                    We help you manage, monitor, mature, & optimize your cloud operations with security, governance, control, & automation
                                    </p>
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


                <Row style={{textAlign:"center", marginTop:"30px"}}>
                    <h1>Technologies</h1>
                </Row>
                <br/>

                <Row>

                    <Col><Image src={azureImg}/></Col>
                    <Col><Image src={ibmImg}/></Col>
                    <Col><Image src={awsImg}/></Col>
                    <Col><Image src={gcpImg}/></Col>
                </Row>



            </Container>




       


        </>
    )
}

export default Cloud;