
import { Col, Container, Row } from "react-bootstrap";
import "./Contact.css"
import contact_us_img from "../images/contact_us.jpg"
function Contact(){
    return (
        <>


        <div id="container-contact">
            <img  id="banner-contact" src={contact_us_img} alt="cloud"/>

            <div id="topleft-contact">CONTACT US</div>
        </div>


        
        
            <Container>

                <Row style={{padding:"30px"}}>

                    <Col>
                        <h1> Global Presence</h1>
                        <p style={{fontSize: "20px"}}><b>Timezone Support:</b> Coverage in all the US Timezones</p>
                        <p style={{fontSize: "20px"}}><b>Office Locations:</b> Headquartered in the US</p>
                        <p style={{fontSize: "20px"}}><b>US Coverage : Serving</b> clients from coast to coast</p>
                    </Col>

                    <Col>
                        <h1> Asia</h1>
                        <h1>North America</h1>
                        
                    </Col>
                </Row>
            </Container>
        
        </>
    )
}

export default Contact;