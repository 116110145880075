import "./Api.css"
import apiImg from "../images/api.jpg"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

import Col from 'react-bootstrap/Col';
import servicesImg from "../images/servicesOffered.jpg"

function Api(){
    return (
        <>

        <div className="container-api">
            <Image fluid id="banner-api" src={apiImg} alt="cloud"/>

            <div className="topleft-api">Api & Microservices</div>
        </div>


        <Container style={{marginBottom:"30px"}}>
                

                <Row style={{marginTop:"20px"}}>
                    <h1>API Economy & Microservices</h1>
                </Row>
                <br/>

                <Row>

                    <Col>

                    <p style={{fontSize:"20px"}}> To become a truly connected enterprise,
it is critical to have a viable strategy from
an integration & process transformation standpoint</p>
                    </Col>

                    <Col>

                    <p style={{fontSize:"20px"}}>
                        Most traditional businesses are undergoing rapid disruptions because of the nexus of four digital forces – mobile, cloud, social media & information patterns. In order to stay competitive & agile, enterprises have to expose all aspects of their operations to digital channels. As a part of this process, it is becoming more & more imperative for enterprises to re-engineer their business processes, channels of interaction & build strategies to deliver connected experiences to their stakeholders. All of this introduces renewed modes of interaction between multiple ecosystems, both within & outside the boundaries of the enterprise. This in turn, significantly drives up the complexities of integration requirements
                    </p>
                    </Col>
                </Row>

                <Row style={{textAlign:"center", marginTop:"80px", marginBottom:"30px"}}>

                    <Col>
                        <h1>Services Offered</h1>
                        <Image src={servicesImg} alt="services offered" width="30%" />
                    </Col>

                    <Col>
                        <ul style={{textAlign:"left"}}>

                        <li>Traditional & Service Based Integration Implementation, Upgrade & Support </li>
                        <li>Integration Factory </li>
                        <li>SOA Architecture Consulting</li> 
                        <li>Microservices Architecture Consulting & Implementations </li>
                        <li>API Discovery, Adoption & Monetization</li>
                        </ul>
                    </Col>
                    
                </Row>

                <Row>

                </Row>

                

                <br/>

            



            </Container>
        </>

    )
}

export default Api;