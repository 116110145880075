import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Navbar.css"
import rdSol from "../images/rd_sol.jpg"
import { FaHouse } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import NavDropdown from 'react-bootstrap/NavDropdown';

function NavComponent() {
  return (

    <Navbar sticky="top" data-bs-theme="light" bg="light"  className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/home">
            <img
              src={rdSol}
              width="100px"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>

          <Nav className="navbar-text">
            <Nav.Link href="/home"><FaHouse /> Home</Nav.Link>
            <Nav.Link href="/contact-us">Contact</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/careers">Careers</Nav.Link>

            <NavDropdown title="Services">
              <NavDropdown.Item href="/cloud" className="navbar-text">Cloud</NavDropdown.Item>
              <NavDropdown.Item href="/api" className="navbar-text">
                API & Microservices
              </NavDropdown.Item>
              <NavDropdown.Item href="/analytics" className="navbar-text">Analytics</NavDropdown.Item>
              
            </NavDropdown>



            {/* <Nav.Link href="/about-us">About</Nav.Link> */}
          </Nav>
      </Container>
    </Navbar>
  );
}

export default NavComponent;